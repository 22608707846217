import { addDays, fromUnixTime, startOfWeek } from "date-fns";
import { format } from "date-fns";
import { de, da, enGB, nb } from "date-fns/locale";
import i18n from "i18next";

export function getLocaleDateFns(): Locale {
  const currentLang = i18n.language;
  let dateFnsLocale: Locale;
  switch (currentLang) {
    case "en":
      dateFnsLocale = enGB;
      break;
    case "da":
      dateFnsLocale = da;
      break;
    case "de":
      dateFnsLocale = de;
      break;
    case "nb":
      dateFnsLocale = nb;
      break;
    default:
      dateFnsLocale = enGB;
  }
  return dateFnsLocale;
}

export function getLocalizedDayName(dayIndex: number) {
  const locale = getLocaleDateFns();
  const start = startOfWeek(new Date(), { weekStartsOn: 0 });
  const day = addDays(start, dayIndex);
  return format(day, "EEEE", { locale: locale });
}

export function parseTimeStamp(timestamp: number): string {
  return format(fromUnixTime(timestamp), "PPp", {
    locale: getLocaleDateFns(),
  });
}
